import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[triggerFocus]'
})
export class TriggerFocusDirective implements OnInit, OnDestroy {

  private _delay: number = 100;

  private targetElement: HTMLElement;
  private observeSubjectSubscription: Subscription;

  @Input()
  set triggerFocus(value: Observable<any>) {
    this.observeSubjectSubscription?.unsubscribe();

    this.observeSubjectSubscription = value?.subscribe?.(val => {
      if (val) {
        this.updateTargetElement();
        setTimeout(() => {
          this.targetElement?.focus?.();
        }, this._delay);
      }
    });
  }

  @Input()
  triggerSelector: string;

  get triggerDelay(): number {
    return this._delay;
  }

  @Input()
  set triggerDelay(value: number) {
    this._delay = typeof value === 'string' ? parseInt(value, 10) : value;
  }

  constructor(private readonly elementRef: ElementRef) {}

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.observeSubjectSubscription?.unsubscribe();
  }

  private updateTargetElement() {
    const host = this.elementRef.nativeElement as HTMLElement;
    if (!this.triggerSelector) {
      this.targetElement = host;
    } else {
      this.targetElement = host.querySelector(this.triggerSelector) as HTMLElement;
    }
  }

}
